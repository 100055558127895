@import "definitions";

$input_min_width: 25em; // mind. Breite für ein Formularfeld
$input_width_small: 2em; // Breite für ein kleines Formularfeld
$input_min_width_small: 5em; // mind. Breite für ein kleines Formularfeld
$textarea_width: 100%; // mind. Breite für eine Textarea
$textarea_height: 10em; // mind. Höhe für eine Textarea
$from_label_min_width: 15em; // mind. Breite für Formularfelder (label)
$form_icon_font_size: 1.5em; // Formular enthält ein Icon - Schriftgröße 
$form_field_height: 2.2em; // Höhe eines Formularfeldes
$form_bg_color: #fff; // Formularfeld Hintergrundfarbe                       
/*Drag & Drop Feld*/
$drap_drop_border_radius: 5px; // Drag & Drop Feld Rahmenrundung
$drag_drop_pic_area_bg: $theme_color_light; // Hintergrund eines Objektes im Drag & Drop Feld
$drag_drop_pic_area_width: 10em; // Breite des Divs welches Bild, Icon, Text einschießt
$drag_drop_pic_area_img_height: 5em; // Höhe eines einzelnen Bildes 
$drag_drop_pic_area_objekt_min_height: 12em; // Höhe des Objekts 
/*Login Form*/
$login_form_full_bg: $theme_color_light; // Hintergrundfarbe der Seite beim Login
$login_form_bg: #fff; // Hintergrundfarbe der Login Box
$login_form_width: 25em; // Breite der Login Box
$login_box_head_pad: $pad 2em; // Innenabstand Login Box Überschrift 
/*Eintrag Infos*/
$form_labels_font_color: #9C9C9C; // meta daten schriftfarbe

select,
textarea,
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
    height: $form_field_height;
    max-width: 100%;
    padding: 0 $pad_tiny;
    border: $border;
    background: $form_bg_color;
    color: $base_font_color;
}

select {
    //ar: seems that selects need to be .1em higher to get proper alignment
    height: $form_field_height + 0.1em;
}

select,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
    font-size: 100%;
}

/*Formulare*/

input,
select,
textarea {
    outline: none;
}

form,
.form_wide,
.form_field_label {
    label {
        font-family: $font_family_bold;
    }
}

.form_wide {
    display: table;
    width: 100%;

    >div,
    >div.form_group>div {
        display: table-row;

        .form_field {

            >input,
            >div {
                vertical-align: top;
            }
        }

        &.first>.form_field_input {
            border-top: $border;
        }

        &.last>.form_field_label {
            border-bottom: none;
        }

        &.date {
            .form_field_input {
                >div {
                    display: table-cell;
                    padding-right: $pad;
                }
            }
        }

        .form_field_label {
            width: 1%;
            min-width: $from_label_min_width;
            white-space: nowrap;
            text-align: right;
            vertical-align: top;
            display: table-cell;
            padding: $pad_small $pad;
            background: $theme_color_light;
            border-bottom: $border_light;

            &.last {
                border-bottom: none;
            }
        }

        .form_field_input {
            border-bottom: $border;
            border-right: $border;
            padding: $pad_small $pad;
            display: table-cell;
            width: 99%;
            vertical-align: middle;
            //this is a hack to prevent codemirror from enlarging formtables
            max-width: 40em;

            .first {
                border-top: $border;
            }

            .add {
                @include sl-icon($fa-var-plus);
            }

            .edit {
                @include sl-icon($fa-var-pencil);
            }

            .pick {
                @include sl-icon($fa-var-search);
            }

            .delete {
                @include sl-icon($fa-var-trash);
            }

            .calendar {
                @include sl-icon($fa-var-calendar fa-2x);
                font-size: $form_icon_font_size;
                margin-left: $mar_small;
            }

            .clock {
                @include sl-icon($fa-var-clock-o fa-2x);
                font-size: $form_icon_font_size;
                margin-left: $mar_small;
            }

            input,
            select {
                vertical-align: middle;
                min-width: $input_min_width;

                &.input_small {
                    min-width: $input_min_width_small;
                    width: $input_width_small;
                }
            }

            .duration_property {
                &>input {
                    min-width: 4em;
                    width: 4em;
                }

                &>select {
                    min-width: calc(#{$input_min_width} - 4.3em);
                }
            }

            select {
                max-width: $input_min_width;
            }

            input[type=checkbox],
            input[type=radio],
            input[type=color] {
                min-width: 0;
                //This alignment doesn't make sense because it missaligns the text for the checkbox
                //vertical-align: top;        
            }

            textarea {
                min-width: $textarea_width;
                height: $textarea_height;
                box-sizing: border-box;
            }

            span.max_chars_warning {
                vertical-align: middle;
                color: green;

                &.warning {
                    color: red;
                    font-weight: bold;
                }
            }

            .type_options_multi .property_group {
                display: inline-block;
                width: 25%;
                margin-bottom: $mar;
            }

            .form_field.type_number_decimal input {
                min-width: 5em;
                width: 10em;
            }

            .readonly.type_html iframe {
                border: $border;
                width: 100%;
                min-height: 350px;
            }
        }
    }

    //entries_action_add_edit_default_form_v2
    &.editor_form {
        .editor_form_group {

            &:not(.last) {
                margin-bottom: 1.5em;
            }

            &.form_group {
                display: block;
            }
        }

        .editor_form_column_wrapper {
            display: flex;

            .editor_form_column {
                flex: 1;

                &:not(:first-child) {
                    margin-left: 1.5em;
                }
            }
        }

        &.form_wide .editor_form_property {
            &.first .form_field_input {
                border-top: $border;
            }

            .form_field_input input {
                min-width: 20em;
            }
        }
    }
}

.options_with_labels {
    .edit {
        @include sl-icon($fa-var-pencil);
    }

    .delete {
        @include sl-icon($fa-var-trash);
    }
}

/*Pflichtfeld*/

.required {
    font-family: $font_family_bold;
}

/*Fehlermeldung im Formular*/

.form_error {
    font-family: $font_family_bold;
}

/*Formular Zwischenüberschrift in der Seitenleiste*/

.sidebar div.form_group div.form_group_head {
    font-family: $font_family_bold;
}

.picker_select_fields .selection {
    margin-bottom: $mar_small;
}

.editor_main_area .view input[size] {
    min-width: 0 !important;
}

div.tabs .form_wide {
    margin-bottom: $mar;
}

/*Suche Tree Dropdown (Kategorien) */

.search.tree_dropdown {
    display: flex;
}

.form_field.type_html .gui_switch {
    text-align: right;
    margin-bottom: .5em;

    select {
        width: auto;
        min-width: 1px !important;
    }
}

/*Formular Gruppen*/

div.form_group {
    display: table-row-group;

    .form_group_head {
        span {
            font-size: $font_size_heading;
            display: table-cell;
            padding: $mar 0 $mar_small 0;
        }
    }
}

div.form_group.first {
    .form_group_head {
        span {
            padding-top: 0;
        }
    }
}

.sidebar div.form_group div.form_group_head {
    font-size: inherit;
}

/*Eintrag Informationen */

.form_labels {
    border-bottom: $border;
    padding: $pad_small 0;
    color: $form_labels_font_color;

    .info {
        @include sl-icon($fa-var-info-circle);
    }
}

.modal_dialog .form_labels {
    border-bottom: none;
}

/*Eintrag bearbeiten*/

div.entries_editor {
    div.view {
        div.search_head {
            margin-top: $mar;
        }

        div.edit_entry_action form>div.form_wide {
            margin: $mar 0;
        }
    }
}

/*Formular in der Seitenleiste*/

div.sidebar {
    div.form_group {
        margin: $mar 0;

        >div {
            margin-bottom: $mar_small;
        }
    }

    div.sidebar_box div.box_head {
        margin-bottom: $mar_small;
    }
}

/*Drag&Drop Felder*/

.form_field.files {
    &:not(.readonly) .resources {
        min-height: $textarea_height;
    }

    .resources {
        margin-top: $mar;
        min-width: $textarea_width;
        border: $border_dashed;
        border-radius: $drap_drop_border_radius;

        .upload {
            @include sl-icon($fa-var-upload);
            padding: $pad 0 $pad_small;
            font-size: $font_size_big_icon;
        }

        .pic_upload,
        .pic_area {
            padding: $pad_small;
            vertical-align: top;
            display: inline-block;
            width: $drag_drop_pic_area_width;
            min-height: $drag_drop_pic_area_objekt_min_height;
            padding: $pad_small;
            text-align: center;
        }

        .pic_area {
            background-color: $drag_drop_pic_area_bg;
            margin: $mar_small;

            >div {
                text-align: center;

                img {
                    max-height: $drag_drop_pic_area_img_height;
                    max-width: 100%;
                }

                &.pic_delete {
                    text-align: right;
                }

                .delete {
                    @include sl-icon($fa-var-times);
                    font-size: $font_size_big_icon;
                    cursor: pointer;
                }

                .arrow-left {
                    @include sl-icon($fa-var-arrow-left);
                    cursor: pointer;
                    padding: $pad_tiny;
                    margin-right: $mar_small;
                }

                .arrow-right {
                    @include sl-icon($fa-var-arrow-right);
                    cursor: pointer;
                    padding: $pad_tiny;
                }
            }
        }

        .click {
            cursor: pointer;
        }
    }

    .resource_list {
        margin: 0 0 $mar 0;
        min-width: $textarea_width;
        min-height: $textarea_height;

        table.resource_table {
            margin-top: 0;

            td.image img {
                max-width: 40px;
                max-height: 40px;
            }
        }

        .add_files>div {
            display: inline-block;
        }

        .add_files .resources {
            min-width: 30em;
            min-height: 5em;
            margin-left: 2em;

            .pic_upload {
                .upload {
                    @include sl-icon($fa-var-upload);
                    font-size: $font_size_big_icon;
                }

                padding: calc(#{$pad_small} - 2px) $pad_small;
                margin-left:$pad;
                vertical-align: top;
                display: inline-block;
                text-align: left;
                width: 12em;
            }
        }

        .click {
            cursor: pointer;
        }
    }

    .add_files {
        button {
            margin-top: $mar;
        }
    }
}

/*Taxonomy Felder*/

.form_field.type_taxonomy {
    .tag {
        border: $border;
        padding: $pad_small;
        margin: 0 $mar_small $mar_small 0;
        display: inline-block;
        cursor: pointer;

        .delete {
            @include sl-icon($fa-var-close);
            margin-right: $mar_small;
        }
    }

    .tag_input_field {
        margin-bottom: $mar_small;
    }
}

.form_field.type_password {
    display: flex;

    span {
        display: flex;
        align-items: center;
        padding: 0 $mar-small;
        border: $border;
        border-left: none;
        border-radius: 0 3px 3px 0;
    }
}

.tags .tag {
    display: inline-block;
    margin: 0 $mar_small $mar_small 0;
    padding: $pad_small;
    border: $border; //1px solid darkgrey;
    border-color: $theme_color;
    cursor: pointer;

    .fa-minus {
        color: #bb0000;
    }

    .fa-plus {
        color: #00bd00;
    }
}

.tags .tag {
    &.region {
        border-color: rgb(1, 124, 224);
    }

    &.country {
        border-color: rgb(236, 182, 4);
    }
}

/* Countries + Regions Autocomplete */
.countries_ac {
    position: relative;

    .ac_entry {
        padding: .3em .6em;

        &.active {
            background-color: #ddd;
        }
    }

    .ac_wrapper {
        position: absolute;
        background: #fff;
        left: 0;
        right: 0;
        border: $border;
    }
}


/* Date picker */
.input.date_picker_field {

    >div {
        display: flex;

        select:nth-child(2) {
            margin-left: .5em;
        }
    }

    div.date_picker_with_time {
        display: flex;
        margin-right: .3em;
        margin-left: .5em;

        span {
            padding-top: 0.4em;
        }

        input[type="text"] {
            margin-right: .3em;
            margin-left: .5em;

            &:nth-child(2) {
                width: 5em;
            }

            &:nth-child(6) {
                width: 2.7em;
            }
        }
    }

    div.date_picker_date {
        display: flex;

        margin-right: .3em;
        margin-left: .5em;

        &>span {
            padding-top: 0.4em;
        }

        input[type="text"] {

            margin-right: .3em;
            margin-left: .5em;

            &:nth-child(1),
            &:nth-child(2) {
                width: 5em;
            }
        }
    }

    i {
        vertical-align: middle;
    }
}

/*Login Formular*/

div.login_form {
    background-color: $login_form_full_bg;
    text-align: center;
    height: 100%;

    div.login_box_head {
        font-size: $font_size_heading;
        border-bottom: $border;
        padding: $login_box_head_pad;

        .login_icon {
            @include sl-icon($fa-var-sign-in);
            margin-right: $mar_small;
        }
    }

    >div.login_form_box {
        background: $login_form_bg;
        text-align: left;
        border: $border;
        width: $login_form_width;
        position: absolute;
        top: 20%;
        left: 40%;

        div.login_box_body {
            padding: $pad $pad_big $pad_big;

            label {
                font-family: $font_family;
            }
        }
    }

    form {
        >div {
            display: table;
        }

        button.button {
            width: 100%;
        }

        div.input {
            margin-bottom: $mar;

            >input {
                margin-top: $mar_tiny;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

//Wird im situationseditor temporär benutzt, da andere formatierung von entries liste nicht greift
.toggle.checkbox {
    @include sl-icon($fa-var-square-o);
}

.toggle.check {
    @include sl-icon($fa-var-check-square-o);
}

.form_field .unit {
    padding-top: $pad_small;
    display: inline-block;
}

.form_field.type_date {
    i {
        padding-top: .15em;
        margin-left: .2em;
    }
}

.form_field.type_categories {
    select {
        display: block;
    }

    select:not(:last-child) {
        margin-bottom: .5em;
    }
}

.type_formfield_config {
    i.right {
        @include sl-icon($fa-var-chevron-right);
    }

    i.left {
        @include sl-icon($fa-var-chevron-left);
    }
}

.options_with_labels {
    i.up {
        @include sl-icon($fa-var-chevron-up);
    }

    i.down {
        @include sl-icon($fa-var-chevron-down);
    }
}

.form_field div.autocomplete {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1000;

    &.active {
        display: inline-block;
    }

    border: solid 1px #ddd;
    background-color:#fff;

    >div {
        padding: .5em;
        border-bottom: solid 1px #ddd;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: #ccc;
        }

        &.selected {
            background-color: #ccc;
        }
    }
}

/* Module statistics*/
.module_statistics {
    .form_wrapper {
        margin-bottom: $mar;

        .form_group {
            .form_field {
                display: table-cell;
                padding-right: $pad;
            }
        }

        button.button {
            margin-top: $mar;
        }
    }

    .subselect {
        margin-top: $mar_small;
    }

    &-table_switch {
        margin-bottom: $mar;
        
        span.is-opened {
            display: none;
        }

        span.is-closed {
            display: inline-block;
        }

        &.is-active {
            span.is-opened {
                display: inline-block;
            }

            span.is-closed {
                display: none;
            }
        }
    }
}


.grouped_categories {
    .group_box {

        .group_heading,
        .subgroup_heading {
            font-weight: bold;
        }

        .group,
        .subgroup {
            select {
                min-width: 0;
                max-width: none;
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 50rem) {
        .group_box:not(:first-child) {

            .group,
            .subgroup {
                padding-top: 1em;
                border-top: solid #ddd 1px;
            }

            .group_heading,
            .subgroup_heading {
                display: none;
            }
        }

        .group_box {
            padding-bottom: 1em;

            &:after {
                display: table;
                content: "";
                clear: both;
            }

            .group,
            .subgroup {
                box-sizing: border-box;
                float: left;
                width: 50%;
            }

            .group {
                padding-right: .5em;
            }

            .subgroup {
                padding-left: .5em;
            }
        }
    }
}

.entries_actions {
    ~h3.copy_label {
        margin-bottom: 0;

        +form {
            padding-top: 0;
        }
    }

    ~form {
        padding-top: 1em;
    }
}



.layout_form_content {

    .is-group_disabled {
        opacity: 0.5;

        .form_field_input {
            cursor: not-allowed;
            background-color: #fafafa;
        }
    }

    .layout_form_property {
        display: flex;

        &:last-child {
            flex-grow: 1;
        }

        &:not(.label_type_top):not(.label_type_narrow)>.form_field_label {
            //width:30%;
            flex: 30;
            max-width: 15em;
            font-family: $font_family_bold;
            text-align: right;
            vertical-align: top;
            padding: $pad_small $pad;
            background: $theme_color_light;
            border-bottom: $border_light;

            &.last {
                border-bottom: none;
            }
        }

        &:not(.label_type_top)>.form_field_input {
            flex: 70;
        }

        &.label_type_wide>.form_field_label {
            //width:45%;
            flex: 45;
        }

        &.label_type_wide>.form_field_input {
            flex: 55;
        }

        &.label_type_narrow {
            &>.form_field_label {
                padding: $pad_small 0 $pad_small $pad;
                display: flex;
                align-items: center;
            }

            &:not(:last-child)>div {
                border-bottom: none;
                border-top: none;
            }

            &:last-child>div {
                border-bottom: $border;
            }

            &.no_border:not(:last-child)>div {
                border-right: none;
            }
        }

        &.label_type_top {
            flex-direction: column;

            >.form_field_label {
                margin-top: $mar_small;
            }

            >.form_field_input {
                padding-top: 0;
                padding-left: 0;

                .form_field.files .resources {
                    margin-top: $mar_small;
                }
            }
        }


    }

    .layout_form_property>.form_field_input,
    .form_field_input_sa {

        border-bottom: $border;
        border-right: $border;
        padding: $pad_small $pad;
        flex-grow: 1;
        //IE11 Fix
        min-height: 1px;


        &.no_padding {
            padding: 0;
            border: none;
        }

        .add {
            @include sl-icon($fa-var-plus);
        }

        .edit {
            @include sl-icon($fa-var-pencil);
        }

        .pick {
            @include sl-icon($fa-var-search);
        }

        .delete {
            @include sl-icon($fa-var-trash);
        }

        .calendar {
            @include sl-icon($fa-var-calendar fa-2x);
            font-size: $form_icon_font_size;
            margin-left: $mar_small;
        }

        .clock {
            @include sl-icon($fa-var-clock-o fa-2x);
            font-size: $form_icon_font_size;
            margin-left: $mar_small;
        }

        input,
        select {
            vertical-align: middle;
        }

        input[type="text"],
        input[type="email"],
        input[type="password"] {
            width: 100%;
            max-width: 30em;

            &.input_small {
                width: 100%;
                max-width: 20em;
            }
        }

        .duration_property {
            &>input {
                min-width: 4em;
                width: 4em;
            }

            &>select {
                min-width: calc(#{$input_min_width} - 4.3em);
            }
        }

        select {
            max-width: $input_min_width;
        }

        input[type=checkbox],
        input[type=radio],
        input[type=color] {
            min-width: 0;
            //This alignment doesn't make sense because it missaligns the text for the checkbox
            //vertical-align: top;        
        }

        textarea {
            min-width: $textarea_width;
            height: $textarea_height;
            box-sizing: border-box;
        }

        span.max_chars_warning {
            vertical-align: middle;
            color: green;

            &.warning {
                color: red;
                font-weight: bold;
            }
        }

        .type_options_multi .property_group {
            display: inline-block;
            width: 25%;
            margin-bottom: $mar;
        }

        .form_field.type_date {
            &>input {
                max-width: 6em;
                min-width: 2em;
            }

            &>i {
                padding-top: 0;
                padding-right: .5em;
                transform: translateY(5px);
            }
        }

        .form_field.type_time {
            &>input {
                max-width: 4.5em;
                min-width: 2em;
            }
        }
    }

    .layout_group,
    .form_group_sa {
        border: 1px solid darken($border_color, 8%);
        padding: $pad;
        margin: $pad 0;

        .group_head {
            font-size: 2em;
            margin-bottom: $mar_small;
        }
    }

    .form_field_input.readonly,
    .form_field_input_sa.readonly {
        display: flex;
        align-items: flex-start;

        .unit {
            padding-top: 0;
        }
    }

    &>:first-child div:first-child .layout_form_property:first-child {
        .form_field_input {
            border-top: $border;
        }
    }

    &>:first-child div:first-child {
        .form_field_input_sa {
            border-top: $border;
        }
    }

    .layout_form_columns>div {

        >div.layout_form_property:first-child .form_field_input,
        .form_field_input_sa {
            border-top: $border;
        }
    }

    .form_field_input.no_padding {
        .layout_form_columns>div {

            >div.layout_form_property:first-child .form_field_input,
            .form_field_input_sa {
                border-top: none;
            }
        }
    }

    &>.layout_form_property.label_type_top>.form_field_input {
        //Looks like crap on file inputs... might need more fine tuning
        border: none;
    }

    .layout_form_columns {
        display: flex;
        margin-bottom: 1em;

        &>div {
            flex: 1 1 0;
            display: flex;
            flex-direction: column;
        }

        &,
        &.narrow {
            >div.wide {
                flex: 1000 1 auto;
            }
        }
    }

    .layout_radio_group,
    .layout_checkbox_group {
        >.layout_group_option {

            //TODO: Only usefull if group is first in whole form...
            //&:not(:first-child) {
            margin-top: $mar_small;
            //}
            margin-bottom: $mar;

            >.group_option_head {
                font-weight: bold;

                input {
                    vertical-align: middle;
                    transform: translateY(-2px);
                    margin-left: 0;
                }
            }

        }
    }

    .layout_form_columns {
        &.narrow {
            >div {
                flex: 0 1 auto;

                &:last-child {
                    flex-grow: 1;
                }
            }
        }

        .flex_2 {
            flex: 2 1 auto;
        }

        .flex_3 {
            flex: 3 1 auto;
        }

        .flex_4 {
            flex: 4 1 auto;
        }

        .flex_5 {
            flex: 5 1 auto;
        }

        .flex_6 {
            flex: 6 1 auto;
        }

        .flex_7 {
            flex: 7 1 auto;
        }

        .flex_8 {
            flex: 8 1 auto;
        }
    }

    .form_field_input_sa.columns_2 {

        .type_options_multi,
        .type_options {
            column-count: 2;
        }
    }

    .form_field_input_sa.dark {
        background-color: $theme_color_light;

        textarea,
        input {
            background-color: transparent;
            border: solid 1px #ccc;
        }
    }
}

.form_field.type_options_multi>label.option {
    display: flex;
    margin-bottom: .3em;

    >div {
        padding-right: .5em;

        input {
            margin: 0;
        }
    }

    >span {
        padding-top: 1px;
        flex: 1 1 auto;
    }
}


.form_field.type_options>.horizontal,
.form_field.type_options_multi>.horizontal {
    display: flex;
    flex-wrap: wrap;

    >label.option {
        display: flex;
        margin-bottom: .3em;
        margin-right: .75em;

        >div {
            padding-right: .25em;

            input {
                margin: 0;
            }
        }

        >span {
            padding-top: 1px;
            flex: 1 1 auto;
        }
    }
}

.select_autocomplete {
    position: relative;
    display: table;

    .options_autocomplete {
        position: absolute;
        background-color: #fff;
        z-index: 10;

        .autocomplete_wrapper {
            box-shadow: 1px 1px 1px #5d5d5d;

            .ac_entry {
                padding: .5em;
                cursor: pointer;
                white-space: nowrap;
                border: $border;
                border-top: none;
                min-width: 13.8em;

                &.active,
                &:hover,
                &:focus {
                    background-color: #eee;
                }

                &:hover {
                    font-weight: bold;
                }
            }
        }
    }

    .select_icon {
        display: table-cell;
        background-color: #fff;
        position: relative;
        left: -1px;
        border: $border;
        border-left: none;
        padding: 0 $pad_small;
    }

    .search_icon {
        padding-left: $pad_small;
    }
}

//autocomplete in edit form
.form_field .select_autocomplete {
    .options_autocomplete .autocomplete_wrapper .ac_entry {
        min-width: 26.5em;
    }
}

.submit_button_text {
    margin-bottom: $mar;
}

.form_field.type_date {
    .picker {
        display: none;

        &.picker--opened {
            display: block;
        }
    }
}

//This is placed here because this should not be deploayed to external guis
.entries_action_subtabs_outer {
    min-height: 35em;
}

.hform_with_label {
    display: flex;

    >.hform_field {
        >.hform_label {
            font-weight: bold;
        }

        >* {
            margin: 0 .5em .5em .5em;
        }
    }
}

div.form_field.entry_picker {
    &>div {
        span.label {
            display: inline-block;
            border: solid 1px $border_color;
            padding: 0 .5em;
            line-height: calc(2.3em - 2px);
            cursor: pointer;

            &>span {
                display: inline-block;
                min-width: 5em;
            }

            &:hover {
                background-color: $highlight_color;
                color: $highlight_font_color;
            }
        }

        >a {
            cursor: pointer;
            display: inline-block;
            margin-left: $mar_tiny;
            border: solid 1px $border_color;
            padding: 0 .8em;
            line-height: calc(2.3em - 2px);

            .delete {
                @include sl-icon($fa-var-trash);
                color: $theme_color;
                cursor: pointer;
            }

            &:hover {
                background-color: $highlight_color;
                color: $highlight_font_color;

                .delete {
                    color: #fff;
                }
            }
        }
    }
}

form .form_submit {
    display: flex;
    justify-content: space-between;
}